<template>
  <hr class="divider text-center" :style="style" :data-content="block.properties.text" />
</template>

<style>
</style>

<script>
export default {
  name: 'BlockDivider',
  props: {
    block: Object,
  },
  computed: {
    style() {
      if (this.block.properties.length < 100) {
        if (this.block.properties.orientation == 'horizontal') {
          return { width: this.block.properties.length + '%' };
        } else {
          return { height: this.block.properties.length + '%' };
        }
      }
      return null;
    },
  },
  data() {
    return {};
  },
};
</script>
