<template>
  <div :class="block.properties.class">
    <div v-for="banner in block.data" class="banner" :class="banner.class" :key="'banner-' + banner.id">
      <p v-if="banner.title" class="title">{{ banner.title }}</p>
      <p v-if="banner.headline" class="headline">{{ banner.headline }}</p>
      <a v-if="banner.image" class="image" :href="banner.href" rel="sponsored" target="_blank" :data-detail="banner.id" :data-uri="banner.uri" data-type="proBanner">
        <img :src="banner.image.src" :srcset="banner.image.srcset" :sizes="banner.image.sizes" :class="banner.image.class" :alt="banner.image.title" :data-detail="banner.id" :data-uri="banner.uri" data-type="proBanner"/>
      </a>
      <p v-if="banner.pitch" class="pitch">{{ banner.pitch }}</p>
      <p v-if="banner.linkText" class="btn">
        <a :href="banner.href" rel="sponsored" target="_blank" class="log-click" :data-detail="banner.id" :data-uri="banner.uri" data-type="proBanner">{{ banner.linkText }}</a>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BlockProBanner',
  props: {
    block: Object,
  },
  data() {
    return {};
  },
  methods: {},
  mounted() {},
};
</script>
