<template>
  <header class="header">
    <div class="header-inner">
      <!-- Left Location-->
      <div class="header-left hide-md">
        <template v-if="location.data.left">
          <component :is="location.data.left.component" :location="location.data.left" v-on="$listeners"></component>

          <template v-for="childBlock in location.data.left.blocks">
            <component :is="childBlock.component" :block="childBlock" :key="'header-left-' + childBlock.id" v-on="$listeners"></component>
          </template>
        </template>
      </div>

      <!-- Center Location-->
      <div class="header-center">
        <div v-if="!location.properties.logo" class="title h1">
          <router-link to="/">{{ location.properties.siteName }}</router-link>
        </div>
        <div v-if="location.properties.logo" class="logo">
          <router-link to="/">
            <img class="img-responsive" :src="location.properties.logo.src" :alt="location.properties.logo.alt" :width="location.properties.logo.width" :height="location.properties.logo.height" />
          </router-link>
        </div>
        <template v-if="location.data.center">
          <component :is="location.data.center.component" :location="location.data.center" v-on="$listeners"></component>

          <template v-for="childBlock in location.data.center.blocks">
            <component :is="childBlock.component" :block="childBlock" :key="'header-center-' + childBlock.id" v-on="$listeners"></component>
          </template>
        </template>
      </div>

      <!-- Right Location-->
      <div class="header-right">
        <template v-if="location.data.right">
          <component :is="location.data.right.component" :location="location.data.right" v-on="$listeners"></component>

          <template v-for="childBlock in location.data.right.blocks">
            <component :is="childBlock.component" :block="childBlock" :key="'header-right-' + childBlock.id" v-on="$listeners"></component>
          </template>
        </template>
      </div>
    </div>

    <!-- Bottom Location -->
    <div class="header-bottom">
      <template v-if="location.data.bottom">
        <component :is="location.data.bottom.component" :location="location.data.bottom" v-on="$listeners"></component>

        <template v-for="childBlock in location.data.bottom.blocks">
          <component :is="childBlock.component" :block="childBlock" :key="'header-center-' + childBlock.id" v-on="$listeners"></component>
        </template>
      </template>
    </div>
  </header>
</template>

<script>
export default {
  name: 'defaultHeader',
  props: {
    location: Object,
  },
  data() {
    return {};
  },
};
</script>
