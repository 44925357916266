<template>
  <section v-if="block.data" :ref="'paperform' + block.id" class="paperform">
    <template v-for="(form, index) in block.data">
      <div
        v-if="activeForm == index"
        :key="'form-' + block.id + '-' + index"
        :data-paperform-id="form.paperformId"
        :data-prefill="'paperformTrackingId=' + form.paperformTrackingId + '&paperformURI=' + block.properties.uri"
        :class="[block.properties.class]"
        :onsubmit="'paperformSubmit.' + block.id"
        data-prefill-inherit="1"
      ></div>
    </template>
  </section>
</template>

<style></style>

<script>
export default {
  name: 'BlockPaperform',
  props: {
    block: Object,
  },
  computed: {},
  created: function() {
    window.paperformSubmit = window.paperformSubmit || {};
    window.paperformSubmit[this.block.id] = this.onSubmit.bind(this);
  },
  methods: {
    onSubmit(submission) {
      let value = submission ? submission.total : 0;

      // Report Facebook Event
      if (window.fbq && this.block.data[this.activeForm]['fbPixelEvent']) {
        window.fbq('trackCustom', this.block.data[this.activeForm]['fbPixelEvent'], {
          tracking_code: this.block.data[this.activeForm]['paperformTrackingId'],
          currency: 'USD',
          value: value,
          uri: this.block.properties.uri,
        });
      }

      // Fire Custom Google Event
      if (window.dataLayer && this.block.data[this.activeForm]['gTagEvent']) {
        let obj = {
          trackingId: this.block.properties.trackingId,
          uri: this.block.properties.uri,
          value: value,
          conversionValue: value,
          transactionId: submission.submission_id,
          transaction_id: submission.submission_id,
        }; // TODO I'm adding a custom value and a custom transaction to make sure they go through, but need to only pass one if they both come through.
        if (window.gtag) {
          if (this.block.properties.adwordsAccount && this.block.data[this.activeForm]['adwordsConversionId']) {
            obj.send_to = this.block.properties.adwordsAccount + '/' + this.block.data[this.activeForm]['adwordsConversionId'];
          }
          window.gtag('event', this.block.data[this.activeForm]['gTagEvent'], obj);
        } else {
          obj.event = this.block.data[this.activeForm]['gTagEvent'];
          obj.adwordsConversionId = this.block.data[this.activeForm]['adwordsConversionId'];
          window.dataLayer.push(obj);
        }
      }

      // TODO add all incoming data to key/val par and pass to next form
      let section = this.$refs['paperform' + this.block.id];
      window.scrollTo(0, section.offsetTop);
      if (this.activeForm < this.block.data.length - 1) {
        this.activeForm++;
      }
    },
  },
  data() {
    return {
      activeForm: 0,
    };
  },
};
</script>
