<template>
  <div v-html="block.data" :class="[block.properties.class]"></div>
  <!-- TODO make this a directive so that there is not a wrapper div ??? -->
</template>

<style></style>

<script>
export default {
  name: 'BlockHtml',
  props: {
    block: Object,
  },
  computed: {},
  data() {
    return {};
  },
};
</script>
