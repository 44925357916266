<template>
  <div v-if="block.data.length" v-html="block.data" class="auto-links">
  </div>
</template>

<style>

</style>

<script>
export default {
  name: 'BlockAutomaticBacklinks',
  props: {
    block: Object,
  },
  data() {
    return {};
  },
};
</script>
