<template>
  <section :class="[block.properties.class]" class="intro" :id="block.properties.id">
    <div class="intro-wrapper">
      <div class="intro-inner columns">
        <div v-for="groupBlock in block.data.groupList.left" :key="'block-' + groupBlock.id" :class="groupBlock.type" class="column col-6 col-md-12">
          <component :is="groupBlock.component" :block="groupBlock"></component>
        </div>
        <div v-for="groupBlock in block.data.groupList.right" :key="'block-' + groupBlock.id" :class="groupBlock.type" class="column col-6 col-md-12">
          <component :is="groupBlock.component" :block="groupBlock"></component>
        </div>
      </div>
    </div>
  </section>
</template>

<style></style>

<script>
export default {
  name: 'groupIntro',
  props: {
    block: Object,
  },
  computed: {},
  data() {
    return {};
  },
};
</script>
