import Vue from 'vue';
import VueRouter from 'vue-router';
import Theme from '@/view/theme.vue';
import oAuth from '@/components/oAuth';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    component: Theme,
    props: () => {
      return { uri: null };
    },
  },
  {
    path: '/admin/oAuth',
    name: 'oAuth',
    component: oAuth,
    props: (route) => {
      return { uri: route.params.state, code: route.params.code };
    },
  },
  {
    path: '/:uri',
    name: 'uri',
    component: Theme,
    props: (route) => {
      return { uri: route.params.uri };
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

export default router;
