<template>
  <span class="icon svg-account" role="img" aria-label="search"></span>
</template>

<style></style>

<script>
export default {
  name: 'blockAccount',
  props: {
    block: Object,
  },
  methods: {},
  data() {
    return {};
  },
};
</script>
