import axios from 'axios';

export default {
  loadURI(uri) {
    return serverPost({ uri: uri }, 'getViewData');
  },
  callBlockMethod(block, method, payload) {
    return serverPost({ block: block, method: method, payload: payload }, 'execute');
  },
  logClick(type, typeDetail, uri) {
    serverPost({ type: type, typeDetail: typeDetail, uri: uri }, 'logClick');
  },
};

// TODO this all needs to be rewritten
function serverPost(data, route, type) {
  type = type || 'json';
  let sessionToken = localStorage.getItem('session_token');
  let parameters = window.location.search ? '&' + window.location.search.slice(1) : '';
  if (sessionToken) {
    data.token = sessionToken;
  }
  data.screenWidth = window.screen.width;
  return axios({
    method: 'POST',
    url: '/api/index.php?route=' + route + parameters,
    responseType: type,
    data: data,
  }).then(handleResponse);
}

function handleResponse(server) {
  if (server.data) {
    if (server.data instanceof Blob) {
      return server.data;
    }
    if (server.data.token) {
      localStorage.setItem('session_token', server.data.token);
    }
    if (server.data.redirect) {
      window.location.href = server.data.redirect;
    }
    if (server.data.error) {
      if (server.data.error.number == 999) {
        // Invalid Token
        localStorage.removeItem('session_token');
        window.location.href = '/login';
      } else {
        console.log(server.data);
        alert(server.data.error.message + ' - See Log For Context.');
      }
    } else {
      return server.data;
    }
  }
}
