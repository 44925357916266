export const cardSlider = {
  computed: {
    gridAutoColumns() {
      if (this.block.data.length == this.block.properties.cards) {
        return (100 - (parseInt(this.block.data.length) - 1)) / this.block.properties.cards + '%'; // Make Full Width
      } else {
        return (100 - (parseInt(this.block.properties.cards) + 2)) / this.block.properties.cards + '%'; // Show A Little Offset
      }
    },
    scrolls() {
      let scrolls = {};
      scrolls.scroll = this.block.data.length > this.block.properties.cards ? true : false;
      scrolls['no-scroll'] = this.block.data.length == this.block.properties.cards ? true : false;
      scrolls['single'] = this.block.data.length == 1 ? true : false;
      return scrolls;
    },
  },
};
