<template>
  <section :class="[block.properties.class]" class="full-width" :id="block.properties.id">
    <div class="inner">
      <div v-for="(group, groupName) in block.data.groupList" :key="groupName" :class="groupName">
        <template v-for="(childBlock, index) in group">
          <component :is="childBlock.component" :block="childBlock" :key="groupName + '-' + index"></component>
        </template>
      </div>
    </div>
  </section>
</template>

<style></style>

<script>
export default {
  name: 'groupFullWidth',
  props: {
    block: Object,
  },
  computed: {},
  data() {
    return {};
  },
};
</script>
