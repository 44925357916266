<template>
  <div :class="block.properties.class" class="card">
    <div v-if="block.data.image" v-html="block.data.image" class="card-image"></div>
    <div class="card-header">
      <div v-if="block.properties.title" class="card-title">{{ block.properties.title }}</div>
      <div v-if="block.properties.subtitle" class="card-subtitle">{{ block.properties.subtitle }}</div>
    </div>
    <div v-if="block.properties.body" v-html="block.properties.body" class="card-body"></div>
    <div v-if="block.properties.action" class="card-footer">
      <router-link v-if="block.properties.actionType == 'route'" :to="block.properties.action" class="btn btn-primary">{{ block.properties.actionText }}</router-link>
      <a v-if="block.properties.actionType == 'external'" :href="block.properties.action" class="btn btn-primary">{{ block.properties.actionText }}</a>
      <a v-if="block.properties.actionType == 'anchor'" href="#" v-scroll-to="{ el: block.properties.action }" class="btn btn-primary">{{ block.properties.actionText }}</a>
      <a v-if="block.properties.actionType == 'emit'" @click="emit(block.properties.action)" class="btn btn-primary">{{ block.properties.actionText }}</a>
    </div>
  </div>
</template>

<style></style>

<script>
export default {
  name: 'BlockCard',
  props: {
    block: Object,
  },
  computed: {},
  data() {
    return {};
  },
};
</script>
