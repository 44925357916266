<template>
  <div :class="block.properties.class" class="banner-wrapper">
    <div v-for="banner in block.data" class="banner" :key="'banner-' + banner.id">
      <a :href="banner.link" rel="sponsored" target="_blank">
        <img :src="banner.src" :alt="banner.alt" data-type="banner" :data-detail="banner.name" :data-uri="banner.uri" class="img-responsive log-click" />
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BlockBanner',
  props: {
    block: Object,
  },
  data() {
    return {};
  },
  methods: {},
  mounted() {},
};
</script>
