<template>
  <hr :ref="handle" class="scrollTrigger" />
</template>

<style>
.scrollTrigger {
  border: none;
  margin: 0;
}
</style>

<script>
export default {
  name: 'scrollTrigger',
  props: {
    handle: {
      type: String,
      required: true,
    },
  },
  data() {
    return {};
  },
  methods: {
    trigger(change) {
      this.$emit('inView', change[0].isIntersecting);
    },
  },
  mounted() {
    const navbarObserver = new window.IntersectionObserver(this.trigger);
    navbarObserver.observe(this.$refs[this.handle]);
  },
};
</script>
