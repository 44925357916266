<template>
  <div class="page-image-header" :class="[block.properties.class]">
    <div v-if="block.data.image" v-html="block.data.image" class="image-wrapper"></div>
    <div v-if="block.data.title" class="title-box">
      <h1 class="title">{{ block.data.title }}</h1>
      <span v-if="block.data.subtitle" class="subtitle">{{ block.data.subtitle }}</span>
    </div>
  </div>
</template>

<style>
.page-image-header {
  position: relative;
  overflow: hidden;
}
.page-image-header img {
  display: block;
  height: auto;
  width: 100%;
}
.page-image-header > .title-box {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.page-image-header .title {
  margin: auto 1rem;
}
.page-image-header .subtitle {
  width: 100%;
}
</style>

<script>
export default {
  name: 'BlockPageImageHeader',
  props: {
    block: Object,
  },
  data() {
    return {};
  },
};
</script>
