<template>
  <svg :viewBox="block.data.viewBox" v-html="block.data.svg" :class="[block.properties.class]"></svg>
</template>

<style></style>

<script>
export default {
  name: 'BlockSvg',
  props: {
    block: Object,
  },
  computed: {},
  data() {
    return {};
  },
};
</script>
