<template>
  <div class="content-inner columns">
    <aside v-if="location.data.left" class="sidebar-left column col-4 hide-md">
      <!-- Left Sidebar Location-->
      <keep-alive>
        <component :is="location.data.left.component" :location="location.data.left"></component>
      </keep-alive>
      <!-- Blocks -->
      <template v-for="childBlock in location.data.left.blocks">
        <keep-alive :key="'sidebar-left-' + childBlock.id">
          <component :is="childBlock.component" :block="childBlock"></component>
        </keep-alive>
      </template>
    </aside>

    <main v-if="location.data.main" class="content-main column col-8 col-md-12">
      <!-- Main Location-->
      <component :is="location.data.main.component" :location="location.data.main"></component>

      <!-- Blocks -->
      <template v-for="childBlock in location.data.main.blocks">
        <component :is="childBlock.component" :block="childBlock" :key="'content-main-' + childBlock.id"></component>
      </template>
    </main>
  </div>
</template>

<style>
</style>

<script>
export default {
  name: 'defaultContent',
  props: {
    location: Object,
  },
  computed: {},
  data() {
    return {};
  },
};
</script>
