<template>
  <div></div>
</template>

<style></style>

<script>
export default {
  name: 'oAuth',
  props: {
    uri: String,
    code: String,
  },
  mounted() {
    // TODO this works for now, but maybe there is a better way
    window.location.href = '/' + this.$route.query.state + '&code=' + this.$route.query.code;
  },
  data() {
    return {
      authCode: '',
    };
  },
};
</script>
