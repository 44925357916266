<template>
  <div class="visitor-container" :class="{ loading: loading, 'past-header': pastHeader }">
    <!-- Header Location-->
    <keep-alive v-if="location.data.header">
      <component :is="location.data.header.component" :location="location.data.header" v-on="$listeners"></component>
    </keep-alive>

    <!-- Header Blocks -->
    <template v-if="location.data.header">
      <template v-for="childBlock in location.data.header.blocks">
        <keep-alive :key="'header-' + childBlock.id">
          <component :is="childBlock.component" :block="childBlock" v-on="$listeners"></component>
        </keep-alive>
      </template>
    </template>

    <scrollTrigger handle="headerBottom" @inView="scrollTriggerHeader" />

    <!-- NavBar Location-->
    <keep-alive v-if="location.data.navBar">
      <component :is="location.data.navBar.component" :location="location.data.navBar" v-on="$listeners"></component>
    </keep-alive>

    <!-- NavBar Blocks -->
    <template v-if="location.data.navBar">
      <template v-for="childBlock in location.data.navBar.blocks">
        <keep-alive :key="'navBar-' + childBlock.id">
          <component :is="childBlock.component" :block="childBlock" v-on="$listeners"></component>
        </keep-alive>
      </template>
    </template>

    <section class="page" :class="location.properties.class">
      <!-- Top Location-->
      <section class="page-top">
        <template v-if="location.data.top">
          <component :is="location.data.top.component" :location="location.data.top" v-on="$listeners"></component>

          <!-- Top Blocks -->
          <template v-for="childBlock in location.data.top.blocks">
            <component :is="childBlock.component" :block="childBlock" :key="'top-' + childBlock.id" v-on="$listeners"></component>
          </template>
        </template>
      </section>

      <!-- Content Location-->
      <main class="page-content">
        <template v-if="location.data.content">
          <component :is="location.data.content.component" :location="location.data.content" v-on="$listeners"></component>

          <!-- Content Blocks -->
          <template v-for="childBlock in location.data.content.blocks">
            <component :is="childBlock.component" :block="childBlock" :key="'content-' + childBlock.id" v-on="$listeners"></component>
          </template>
        </template>
      </main>

      <!-- Bottom Location-->
      <section class="page-bottom">
        <template v-if="location.data.bottom">
          <component :is="location.data.bottom.component" :location="location.data.bottom" v-on="$listeners"></component>

          <!-- Bottom Blocks -->
          <template v-for="childBlock in location.data.bottom.blocks">
            <component :is="childBlock.component" :block="childBlock" :key="'bottom-' + childBlock.id" v-on="$listeners"></component>
          </template>
        </template>
      </section>
    </section>

    <!-- Footer Location-->
    <keep-alive v-if="location.data.footer">
      <component :is="location.data.footer.component" :location="location.data.footer" v-on="$listeners"></component>
    </keep-alive>

    <!-- Footer Blocks -->
    <template v-if="location.data.footer">
      <template v-for="childBlock in location.data.footer.blocks">
        <keep-alive :key="'footer-' + childBlock.id">
          <component :is="childBlock.component" :block="childBlock" v-on="$listeners"></component>
        </keep-alive>
      </template>
    </template>
  </div>
</template>

<style>
/* Icons */
@import '../assets/icons.css';

#toast {
  top: 90vh;
  right: 2vw;
  width: 35vw;
  z-index: 4;
  box-shadow: 0 6px 5px rgb(0 0 0 / 35%);
}

#toast.toast-primary {
  background: #fffb00;
  border: #c0bd02;
  box-shadow: 0 6px 5px rgba(0, 0, 0, 0.35);
}

/* Color Overides */
.text-primary {
  color: white !important;
}
.text-secondary {
  color: black !important;
}
.bg-primary {
  background: #48ff00 !important;
}
.bg-secondary {
  background: #fbb03b !important;
}
.btn-primary {
  color: rgb(0, 0, 0) !important;
  background: #48ff00 !important;
}

.loading {
  color: transparent !important;
}

/* Frame */
.visitor.container {
  display: grid;
  height: 100vh;
  grid-template-rows: auto auto 1fr auto;
  gap: 24px;
}
.visitor.container:nth-child(2) {
  padding-top: 150px;
}
.home .page-content {
  text-align: center;
}
.page-bottom {
  padding: 0 0.8rem;
}

/* Form */
.form {
  color: rgb(0, 0, 0);
}
.form .form-header {
  border-bottom: 1px solid rgb(220, 220, 220);
}
.form .title {
  font-size: 2vw;
  font-weight: 400;
  text-transform: uppercase;
}
.form .form-footer .btn {
  margin-left: 1vw;
}

.form.employment .form-body {
  display: flex;
  flex-wrap: wrap;
  margin-left: -0.4rem;
  margin-right: -0.4rem;
}

/* Responsive */
@media (max-width: 960px) {
}
@media (max-width: 840px) {
}
@media (max-width: 600px) {
  .form .title {
    font-size: 4vw;
  }
}
</style>

<script>
export default {
  name: 'heritagePage',
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    location: Object,
  },
  methods: {
    scrollTriggerHeader(inView) {
      this.pastHeader = !inView;
    },
  },
  data() {
    return {
      pastHeader: false,
    };
  },
};
</script>
