export const blockLoader = {
  methods: {
    loadBlock(block, themeCode) {
      if (block.type == 'location') {
        block.component = () => import(`@/view/theme/${themeCode}/locations/${block.file}`);
        Object.keys(block.data).forEach((location) => {
          if (block.data[location].type == 'location') {
            this.loadBlock(block.data[location], themeCode);
          }
          if (block.data[location].blocks) {
            block.data[location].blocks.forEach((block) => {
              this.loadBlock(block, themeCode);
            });
          }
        });
      } else if (block.type == 'group') { // TODO Rework this I just quickly added group
        block.component = () => import(`@/view/theme/${themeCode}/groups/${block.file}`);
        Object.keys(block.data.groupList).forEach((group) => {
          block.data.groupList[group].forEach(block => {
            this.loadBlock(block, themeCode);
          });
        });
      } else {
        // TODO check for block file
        // TODO check for block file in theme first if not fall back to standard block
        block.component = () => import(`@/view/blocks/${block.file}`);
      }
    },
  },
};
