<template>
  <div class="faq">
    <div v-for="(group, groupName) in block.data" :key="groupName" class="faq-group">
      <h2 class="group-title">{{ groupName }}</h2>
      <div class="question-list">
        <details v-for="(faq, faqIndex) in group" :key="'faq-' + faqIndex" class="accordion">
          <summary class="question accordion-header">
            <i class="icon svg-right"></i>
            <span v-html="faq.question"></span>
          </summary>
          <div class="answer accordion-body">
            <span v-html="faq.answer"></span>
          </div>
        </details>
      </div>
    </div>
  </div>
</template>

<style>
.faq .question {
  position: relative;
}
</style>

<script>
export default {
  name: 'BlockFaq',
  props: {
    block: Object,
  },
  computed: {},
  data() {
    return {};
  },
};
</script>
