<template>
  <div :class="[block.data.class]" :id="block.properties.id">
    <ul v-for="(group, groupName) in block.data.groupList" :key="groupName" :class="groupName">
      <li v-for="(childBlock, index) in group" :key="groupName + '-' + index" :class="childBlock.type" class="app-box-item">
        <component :is="childBlock.component" :block="childBlock"></component>
      </li>
    </ul>
  </div>
</template>

<style>
/* Icons */
.app-box {
  display: inline-block;
  list-style-type: none;
  margin: 0;
  margin-right: 2vw;
}
.app-box .app-box-item {
  display: inline-block;
  margin: 0;
  margin-left: 2vw;
}
.app-box .icon {
  background-color: rgb(222, 220, 220);
}
.app-box .nav.not-mobile {
  margin: 0;
}
.app-box .navigation {
  display: none;
}
.app-box .navigation .mobile-nav-toggle {
  vertical-align: baseline;
  padding-bottom: 1.5rem;
  background: transparent;
  border-color: rgb(222, 220, 220);
  border-radius: 0.3rem;  
}


/* Responsive */
@media (max-width: 840px) {
  .app-box .navigation {
    display: inline-block;
  }
  .app-box .search {
    display: none;
  }
  .app-box .account {
    display: none;
  }  
}
</style>

<script>
export default {
  name: 'groupAppBox',
  props: {
    block: Object,
  },
  computed: {},
  data() {
    return {};
  },
};
</script>
