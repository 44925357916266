<template>
  <div :class="block.properties.class">{{ block.properties.verbage }}</div>
</template>

<script>
export default {
  name: 'BlockDisclaimer',
  props: {
    block: Object,
  },
  data() {
    return {};
  },
  methods: {},
  mounted() {},
};
</script>
