<template>
  <article :class="[block.data.class]" class="card-popover" :id="block.properties.id">
    <div v-for="(group, groupName) in block.data.groupList" :key="groupName" :class="groupName">
      <button @click="hideCard" class="btn btn-clear float-right"></button>
      <template v-for="(childBlock, index) in group">
        <component :is="childBlock.component" :block="childBlock" :key="groupName + '-' + index"></component>
      </template>
    </div>
  </article>
</template>

<style>
.card-popover {
  position: absolute;
  display: none;
  z-index: 2;
  color: black;
}
.card-popover .btn-clear {
  background: rgb(255, 0, 0);
  width: 1.5rem;
  height: 1.5rem;
}
.card-popover .card {
  box-shadow: rgb(50 50 50) 0px 0.25rem 1rem;
  border: 0;
}
.card-popover .card-image > img {
  width: 100%;
}
.card-popover .card-title {
  font-weight: 500;
  font-size: 1rem;
}
.card-popover .card-subtitle {
  color: rgb(188, 195, 206);
}
</style>

<script>
export default {
  name: 'groupPopover',
  props: {
    block: Object,
  },
  methods: {
    hideCard(e) {
      e.target.closest('article').style.display = 'none';
    },
  },
  computed: {},
  data() {
    return {};
  },
};
</script>
