<template>
  <img :src="image.src" :srcset="image.srcset" :sizes="image.sizes" :class="[image.class]" :alt="image.alt" :width="image.width" :height="image.height" />
</template>

<style></style>

<script>
export default {
  name: 'BlockImage',
  props: {
    block: Object,
  },
  computed: {
    image() {
      return this.block.data;
    },
  },
  data() {
    return {};
  },
};
</script>
