<template>
  <span class="link" :class="[block.properties.class]">
    <router-link v-if="block.properties.type == 'route'" :to="block.properties.navigateTo" class="btn btn-link">{{ block.properties.text }}</router-link>
    <a v-if="block.properties.type == 'external'" :href="block.properties.navigateTo" class="btn btn-link">{{ block.properties.text }}</a>
    <a v-if="block.properties.type == 'anchor'" href="#" v-scroll-to="{ el: block.properties.navigateTo, offset: parsedOffset }" class="btn btn-link">{{ block.properties.text }}</a>
    <a v-if="block.properties.type == 'emit'" @click="emit(block.properties.navigateTo)" class="btn btn-link">{{ block.properties.text }}</a>
  </span>
</template>

<style></style>

<script>
export default {
  name: 'blockLink',
  props: {
    block: Object,
  },
  computed: {},
  methods: {
    emit(emitPath) {
      emitPath = emitPath.split('/');
      if (emitPath.length === 2) {
        let eventName = emitPath[0];
        let eventValue = emitPath[1];
        this.$root.$emit(eventName, eventValue);
      } else {
        this.$emit('toast', { butter: 'error', message: 'Emit Path Not Improperly Structured!' });
      }
    },
  },
  data() {
    return {
      authCode: '',
    };
  },
};
</script>
