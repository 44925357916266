<template>
  <!-- TODO This does not support name arrays yet -->
  <form :class="[{ modal: modal, active: active }, block.properties.formCode, block.properties.class]" class="form" @submit.prevent="submit">
    <div v-if="modal" @click="active = false" class="modal-overlay" aria-label="Close"></div>
    <div :class="{ 'modal-container': modal }">
      <div :class="{ 'modal-header': modal }" class="form-header">
        <button v-if="modal" @click="active = false" type="button" class="btn btn-clear float-right" aria-label="Close"></button>
        <div v-if="block.properties.title" :class="{ 'modal-title': modal }" class="title">{{ block.properties.title }}</div>
      </div>
      <div v-for="(error, errorIndex) in submitErrors" :key="'error-' + errorIndex" class="toast toast-error">
        <button type="button" @click="clearError(errorIndex)" class="btn btn-clear float-right"></button>
        {{ error }}
      </div>
      <div :class="{ 'modal-body': modal }" class="form-body">
        <div v-for="field in block.data.fields" :class="field.class" class="form-group" :key="formElementId(field)">
          <!-- Text Field Elements -->
          <template v-if="field.element == 'input'">
            <label v-if="field.label" class="form-label" :for="formElementId(field)">{{ field.label }}</label>
            <input
              v-model="values[field.name]"
              :type="field.type"
              :id="formElementId(field)"
              :name="field.name"
              :placeholder="field.placeholder"
              :title="field.title"
              :pattern="field.pattern"
              :minLength="field.minLength"
              :maxLength="field.maxLength"
              :step="field.step"
              :readOnly="field.readOnly"
              :disabled="field.disabled"
              :required="field.required"
              :list="dataList(field)"
              :class="[{ required: field.required }, field.type]"
              class="form-input"
            />
            <datalist v-if="dataList(field)" :id="dataList(field)">
              <option v-for="(item, itemIndex) in field.items" :value="item.text" :key="formElementId(field) + '-' + itemIndex" />
            </datalist>
          </template>

          <!-- Text Area -->
          <template v-if="field.element == 'textarea'">
            <label v-if="field.label" class="form-label" :for="formElementId(field)">{{ field.label }}</label>
            <textarea
              v-model="values[field.name]"
              :id="formElementId(field)"
              :name="field.name"
              :placeholder="field.placeholder"
              :maxLength="field.maxLength"
              :readOnly="field.readOnly"
              :disabled="field.disabled"
              :required="field.required"
              :class="{ required: field.required }"
              class="form-input"
            ></textarea>
          </template>

          <!-- Checkbox -->
          <label v-if="field.element == 'checkbox'" class="form-checkbox">
            <input
              v-model="values[field.name]"
              :id="formElementId(field)"
              :name="field.name"
              :title="field.title"
              :readOnly="field.readOnly"
              :disabled="field.disabled"
              :required="field.required"
              :class="[{ required: field.required }, field.type]"
              type="checkbox"
            />
            <i class="form-icon"></i> {{ field.label }}
          </label>

          <!-- TODO Make it select default value -->
          <template v-if="field.element == 'select'">
            <label v-if="field.label" class="form-label" :for="formElementId(field)">{{ field.label }}</label>
            <select v-model="values[field.name]" :id="formElementId(field)" :name="field.name" :class="{ required: field.required }" class="form-select">
              <option v-for="(item, itemIndex) in field.items" :value="item.value" :key="formElementId(field) + '-' + itemIndex">{{ item.text }}</option>
            </select>
          </template>

          <!-- TODO Add validation messages and make this swap out  -->
          <p v-if="field.hint" class="form-input-hint">{{ field.hint }}</p>
        </div>
      </div>
      <div :class="{ 'modal-footer': modal }" class="form-footer">
        <button type="button" v-if="block.properties.clearBtnText" @click="initiate()" class="btn">{{ block.properties.clearBtnText }}</button>
        <button type="submit" v-if="block.properties.submitBtnText" :class="{ loading: loading }" class="btn btn-primary">{{ block.properties.submitBtnText }}</button>
      </div>
    </div>
  </form>
</template>

<style></style>

<script>
import api from '@/api/api.service.js';
export default {
  name: 'BlockForm',
  props: {
    block: Object,
  },
  computed: {
    modal() {
      return this.block.properties.modal;
    },
  },
  methods: {
    initiate() {
      this.values = {};
      this.submitErrors = [];
      this.$set(this.values, 'formCode', this.block.properties.formCode);
      this.block.data.fields.forEach((field) => {
        this.$set(this.values, field.name, field.defaultValue);
      });
    },
    clearError(index) {
      this.submitErrors.splice(index, 1);
    },
    formElementId(field) {
      return field.idOveride ? field.idOveride : 'form-' + this.block.data.id + '-field-' + field.id;
    },
    dataList(field) {
      return field.items.length ? 'data-' + this.formElementId(field) : null;
    },
    submit() {
      this.loading = true;
      this.submitErrors = [];
      // TODO Add Check for All Form Extended Validations
      api.callBlockMethod('form', 'submit', this.values).then((response) => {
        this.loading = false;
        if (response) {
          if (response.type == 'success') {
            this.initiate();
            this.active = false;
          }
          for (const action in response.message) {
            if (response.message[action].type == 'error') {
              this.submitErrors.push(response.message[action].message);
            } else {
              this.$emit('toast', { butter: 'success', message: response.message[action].message, class: this.block.properties.toastClass });
            }
          }
        }
      });
    },
  },
  mounted() {
    // Listen For Other Components that may Want To Activate / Deactivate This Form
    this.$root.$on('form-' + this.block.properties.formCode, (state) => {
      this.active = state;
    });
    this.initiate();
  },
  data() {
    return {
      active: false,
      values: {},
      loading: false,
      submitErrors: [],
    };
  },
};
</script>
