<template>
<div class="adsbygoogle"></div>
  <!-- <ins
    v-if="block.properties.adSense"
    class="adsbygoogle"
    style="display:block"
    :data-ad-client="this.block.properties.adSense"
    :data-ad-slot="this.block.properties.adSlot"
    data-ad-format="auto"
    data-full-width-responsive="true"
  ></ins> -->
  <!-- https://stackoverflow.com/questions/57632161/adding-auto-ads-with-vue-js -->
</template>

<style></style>

<script>
export default {
  name: 'BlockGoogleAdsense',
  props: {
    block: Object,
  },
  data() {
    return {};
  },
  head: {
    // script: function() {
    //   let scripts = [];
    //   if (process.env.NODE_ENV == 'production') {
    //     if (this.block.properties.adSense) {
    //       scripts.push({ src: 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=' + this.block.properties.adSense, crossorigin: 'anonymous', id: 'adSense' });
    //     }
    //   }
    //   return scripts;
    // },
  },
  // beforeRouteUpdate(to, from, next) {
  //   let adsbygoogle = window.adsbygoogle || [];
  //   adsbygoogle.push({});
  //   next();
  // },
  // beforeRouteLeave(to, from, next) {
  //   let adsbygoogle = window.adsbygoogle || [];
  //   adsbygoogle.push({});
  //   next();
  // },
};
</script>
