<template>
  <div class="content-inner">
    <div v-if="location.data.top" class="content-top">
      <!-- Content Top Location-->
      <component :is="location.data.top.component" :location="location.data.top"></component>

      <!-- Blocks -->
      <template v-for="childBlock in location.data.top.blocks">
        <component :is="childBlock.component" :block="childBlock" :key="'content-top-' + childBlock.id"></component>
      </template>
    </div>

    <aside v-if="location.data.left" class="sidebar-left">
      <!-- Left Sidebar Location-->
      <keep-alive>
        <component :is="location.data.left.component" :location="location.data.left" v-on="$listeners"></component>
      </keep-alive>
      <!-- Blocks -->
      <template v-for="childBlock in location.data.left.blocks">
        <keep-alive :key="'sidebar-left-' + childBlock.id">
          <component :is="childBlock.component" :block="childBlock" v-on="$listeners"></component>
        </keep-alive>
      </template>
    </aside>

    <main v-if="location.data.main" class="content-main">
      <!-- Main Location-->
      <component :is="location.data.main.component" :location="location.data.main" v-on="$listeners"></component>

      <!-- Blocks -->
      <template v-for="childBlock in location.data.main.blocks">
        <component :is="childBlock.component" :block="childBlock" :key="'content-main-' + childBlock.id" v-on="$listeners"></component>
      </template>
    </main>

    <aside v-if="location.data.right" class="sidebar-right">
      <!-- Right Sidebar Location-->
      <keep-alive>
        <component :is="location.data.right.component" :location="location.data.right" v-on="$listeners"></component>
      </keep-alive>
      <!-- Blocks -->
      <template v-for="childBlock in location.data.right.blocks">
        <keep-alive :key="'sidebar-right-' + childBlock.id">
          <component :is="childBlock.component" :block="childBlock" v-on="$listeners"></component>
        </keep-alive>
      </template>
    </aside>

    <div v-if="location.data.bottom" class="content-bottom">
      <!-- Content Bottom Location-->
      <component :is="location.data.bottom.component" :location="location.data.bottom" v-on="$listeners"></component>

      <!-- Blocks -->
      <template v-for="childBlock in location.data.bottom.blocks">
        <component :is="childBlock.component" :block="childBlock" :key="'content-bottom-' + childBlock.id" v-on="$listeners"></component>
      </template>
    </div>
  </div>
</template>

<style>

</style>

<script>
export default {
  name: 'defaultContent',
  props: {
    location: Object,
  },
  computed: {
    layout() {
      let layout = 'single';
      if ('left' in this.block.data && 'right' in this.block.data) {
        layout = 'full';
      } else if ('left' in this.block.data && !('right' in this.block.data)) {
        layout = 'left-only';
      } else if ('right' in this.block.data && !('left' in this.block.data)) {
        layout = 'right-only';
      }
      return layout;
    },
  },
  data() {
    return {};
  },
};
</script>
