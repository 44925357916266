<template>
  <div :class="[block.properties.class]" class="group-overlay" :id="block.properties.id">
    <div class="overlay-wrapper">
      <div v-for="groupBlock in block.data.groupList.overlay" :key="'block-' + groupBlock.id" :class="groupBlock.type" class="overlay">
        <component :is="groupBlock.component" :block="groupBlock"></component>
      </div>
      <div v-for="groupBlock in block.data.groupList.underlay" :key="'block-' + groupBlock.id" :class="groupBlock.type" class="underlay">
        <component :is="groupBlock.component" :block="groupBlock"></component>
      </div>
    </div>
  </div>
</template>

<style></style>

<script>
export default {
  name: 'groupOverlay',
  props: {
    block: Object,
  },
  data() {
    return {};
  },
};
</script>
