<template>
  <header class="header">
    <div class="inner">
      <!-- Left Location-->
      <div class="left">
        <div class="branding">
          <div v-if="!location.properties.logo" class="title h1">
            <router-link to="/">{{ location.properties.siteName }}</router-link>
          </div>
          <div v-if="location.properties.logo" class="logo">
            <router-link to="/">
              <img class="img-responsive" :src="location.properties.logo.src" :alt="location.properties.logo.alt" :width="location.properties.logo.width" :height="location.properties.logo.height" />
            </router-link>
          </div>
        </div>

        <template v-if="location.data.left">
          <component :is="location.data.left.component" :location="location.data.left" v-on="$listeners"></component>

          <template v-for="childBlock in location.data.left.blocks">
            <component :is="childBlock.component" :block="childBlock" :key="'header-left-' + childBlock.id" v-on="$listeners"></component>
          </template>
        </template>
      </div>

      <!-- Center Location-->
      <div class="center">
        <template v-if="location.data.center">
          <component :is="location.data.center.component" :location="location.data.center" v-on="$listeners"></component>

          <template v-for="childBlock in location.data.center.blocks">
            <component :is="childBlock.component" :block="childBlock" :key="'header-center-' + childBlock.id" v-on="$listeners"></component>
          </template>
        </template>
      </div>

      <!-- Right Location-->
      <div class="right">
        <template v-if="location.data.right">
          <component :is="location.data.right.component" :location="location.data.right" v-on="$listeners"></component>

          <template v-for="childBlock in location.data.right.blocks">
            <component :is="childBlock.component" :block="childBlock" :key="'header-right-' + childBlock.id" v-on="$listeners"></component>
          </template>
        </template>
      </div>
    </div>

    <!-- Bottom Location -->
    <div class="bottom">
      <template v-if="location.data.bottom">
        <component :is="location.data.bottom.component" :location="location.data.bottom" v-on="$listeners"></component>

        <template v-for="childBlock in location.data.bottom.blocks">
          <component :is="childBlock.component" :block="childBlock" :key="'header-center-' + childBlock.id" v-on="$listeners"></component>
        </template>
      </template>
    </div>
  </header>
</template>

<style>

</style>

<script>
export default {
  name: 'cinchHeader',
  props: {
    location: Object,
  },
  head: {
    title: function() {
      return {
        inner: this.location.properties.pageTitle,
        separator: '|',
        complement: this.location.properties.siteName,
      };
    },
    meta: function() {
      return [
        // Basic
        { rel: 'canonical', href: this.location.properties.path, id: 'canonical' },
        { name: 'description', content: this.location.properties.synopsis, id: 'synopsis' },
        // Twitter Share
        { name: 'twitter:title', content: this.location.properties.pageTitle, id: 'twitterTitle' },
        { name: 'twitter:url', content: this.location.properties.path, id: 'twitterUrl' },
        { name: 'twitter:description', content: this.location.properties.synopsis, id: 'twitterDesc' },
        // Open Graph
        { name: 'og:site_name', content: this.location.properties.siteName, id: 'ogSite' },
        { name: 'og:type', content: 'website', id: 'ogType' },
        { name: 'og:title', content: this.location.properties.pageTitle, id: 'ogTitle' },
        { name: 'og:description', content: this.location.properties.synopsis, id: 'ogDesc' },
        { name: 'og:url', content: this.location.properties.path, id: 'ogUrl' },
      ];
    },
  },
  data() {
    return {};
  },
};
</script>
