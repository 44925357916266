<template>
<section class="structured-article mt-2">
  <article>
    <h1>The Page Title - Only H1 unless jumbotron</h1>

    <!-- Full Width Images -->
    <div class="image-wrapper-full">
      <img class="img-responsive img-fit-cover rounded" src="http://via.placeholder.com/1540x750.png" alt="Some Alt Message" />
    </div>
    <p>
      When to Use Lorem Ipsum generally, lorem ipsum is best suited to keeping templates from looking bare or minimizing the distractions of draft copy. Second, use lorem ipsum if you think the
      placeholder text will be too distracting. For specific projects, collaboration between copywriters and designers may be best, however, like Karen McGrane said, draft copy has a way of turning
      any meeting about layout decisions into a discussion about word choice. So don’t be afraid to use lorem ipsum to keep everyone focused.
    </p>
    <p>
      When to Use Lorem Ipsum generally, lorem ipsum is best suited to keeping templates from looking bare or minimizing the distractions of draft copy. Second, use lorem ipsum if you think the
      placeholder text will be too distracting. For specific projects, collaboration between copywriters and designers may be best, however, like Karen McGrane said, draft copy has a way of turning
      any meeting about layout decisions into a discussion about word choice. So don’t be afraid to use lorem ipsum to keep everyone focused.
    </p>

    <!-- Sections -->
    <h2>Section Header</h2>
    <p>
      When to Use Lorem Ipsum generally, lorem ipsum is best suited to keeping templates from looking bare or minimizing the distractions of draft copy. Second, use lorem ipsum if you think the
      placeholder text will be too distracting. For specific projects, collaboration between copywriters and designers may be best, however, like Karen McGrane said, draft copy has a way of turning
      any meeting about layout decisions into a discussion about word choice. So don’t be afraid to use lorem ipsum to keep everyone focused.
    </p>

    <!-- Figures -->
    <figure class="figure">
      <img class="img-responsive img-fit-cover" src="http://via.placeholder.com/1540x750.png" alt="Some Alt Message" />
      <figcaption class="figure-caption text-center">Some Caption</figcaption>
    </figure>

    <!-- Float Images -->
    <h2>Section Header Must Be H2</h2>
    <p>
      When to Use Lorem Ipsum generally, <img class="img-responsive float-right img-fit-cover" src="http://via.placeholder.com/250x250.png" alt="Some Alt Message" /> lorem ipsum is best suited to
      keeping templates from looking bare or minimizing the distractions of draft copy. Second, use lorem ipsum if you think the placeholder text will be too distracting. For specific projects,
      collaboration between copywriters and designers may be best, however, like Karen McGrane said, draft copy has a way of turning any meeting about layout decisions into a discussion about word
      choice. So don’t be afraid to use lorem ipsum to keep everyone focused.
    </p>
    <p>
      When to Use Lorem Ipsum generally, <img class="img-responsive float-left img-fit-cover" src="http://via.placeholder.com/250x250.png" alt="Some Alt Message" /> lorem ipsum is best suited to
      keeping templates from looking bare or minimizing the distractions of draft copy. Second, use lorem ipsum if you think the placeholder text will be too distracting. For specific projects,
      collaboration between copywriters and designers may be best, however, like Karen McGrane said, draft copy has a way of turning any meeting about layout decisions into a discussion about word
      choice. So don’t be afraid to use lorem ipsum to keep everyone focused.
    </p>

    <!-- Block Quote -->
    <div class="clearfix"> <!-- Question - How to fox this layout issue -->
      <blockquote>
        <p>The advance of technology is based on making it fit in so that you don't really even notice it, so it's part of everyday life.</p>
        <cite>- Bill Gates</cite>
      </blockquote>
    </div>
  </article>
    <hr class="divider text-center" data-content="Similar Topics"/>
    <div class="tag-btn-box">
      <button class="btn btn-link">Tag Name</button>
      <button class="btn btn-link">Tag Name</button>
      <button class="btn btn-link">Tag Name</button>
      <button class="btn btn-link">Tag Name</button>
    </div>
    <hr class="divider"/>
  </section>
</template>

<style>
.structured-article p {
  text-align: justify;
  display: flow-root;
  clear: both;
}
.structured-article .image-wrapper-full {
  width: 100%;
  padding: 0.4rem 0;
  display: flex;
  justify-content: center;
}
.structured-article .figure {
  padding: 0.2rem;
  border: 1px solid lightgray;
}
.structured-article .float-right,
.structured-article .float-left {
  margin: 0.2rem;
  max-width: 35%;
  max-height: 250px;
  height: auto;
}
.structured-article .float-right {
  margin-left: 0.4rem;
}
.structured-article .float-left {
  margin-right: 0.4rem;
}
.structured-article .tag-btn-box .btn:not(:last-child) {
  margin-right: .2rem;
}
</style>

<script>
export default {
  name: 'BlockStructuredArticle',
  props: {
    block: Object,
  },
  data() {
    return {};
  },
};
</script>
