<template>
  <footer class="footer container">
    <section v-if="location.properties.logo" class="footer-logo">
      <h5 v-if="is.empty(location.properties.logo)" class="mt-2">{{ location.properties.siteName }}</h5>
      <img
        v-if="!is.empty(location.properties.logo)"
        class="img-responsive m-2"
        :src="location.properties.logo.src"
        :alt="location.properties.logo.alt"
        :width="location.properties.logo.width"
        :height="location.properties.logo.height"
      />
    </section>
    <section v-if="location.data.top" class="footer-top">
      <!-- Top Location-->
      <template>
        <component :is="location.data.top.component" :location="location.data.top" v-on="$listeners"></component>

        <template v-for="childBlock in location.data.top.blocks">
          <component :is="childBlock.component" :block="childBlock" :key="'footer-top-' + childBlock.id" v-on="$listeners"></component>
        </template>
      </template>
    </section>
    <section v-if="location.data.middle" class="footer-middle">
      <!-- Middle Location-->
      <template>
        <component :is="location.data.middle.component" :location="location.data.middle" v-on="$listeners"></component>

        <template v-for="childBlock in location.data.middle.blocks">
          <component :is="childBlock.component" :block="childBlock" :key="'footer-middle-' + childBlock.id" v-on="$listeners"></component>
        </template>
      </template>
    </section>
    <section v-if="location.data.bottom" class="footer-bottom">
      <!-- Bottom Location-->
      <template>
        <component :is="location.data.bottom.component" :location="location.data.bottom" v-on="$listeners"></component>

        <template v-for="childBlock in location.data.bottom.blocks">
          <component :is="childBlock.component" :block="childBlock" :key="'footer-bottom-' + childBlock.id" v-on="$listeners"></component>
        </template>
      </template>
    </section>
    <section v-if="location.properties.copyright" class="copyright">{{ location.properties.copyright }}</section>
  </footer>
</template>

<style>
.footer {
  background-color: lightgray;
  text-align: center;
}
.footer > section {
  margin-bottom: .75rem;
}
.footer > section:last-of-type {
  margin-bottom: 0;
}

.footer-logo {
  display: flex;
  justify-content: center;
}
.footer .social-box {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: inline-flex;
  flex-wrap: wrap;
}
.footer .social-icon {
  margin: 10px;
}
.footer .social-icon > span {
  background-color: black;
}
.footer .copyright {
  margin: 0;
}
</style>

<script>
export default {
  name: 'cinchFooter',
  props: {
    location: Object,
  },
  data() {
    return {};
  },
  mounted() {},
};
</script>
