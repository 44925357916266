<template>
  <div class="carousel">
    <figure>
      <img
        :src="currentSlide.img.src"
        :srcset="currentSlide.img.srcset"
        :sizes="currentSlide.img.sizes"
        :alt="currentSlide.img.alt"
        :title="currentSlide.img.title"
        :width="currentSlide.img.width"
        :height="currentSlide.img.height"
      />
      <figcaption>
        <router-link :to="currentSlide.path">
          <span class="title">{{ currentSlide.title }}</span>
          <span v-if="currentSlide.subtitle" class="subtitle">{{ currentSlide.subtitle }}</span>
        </router-link>
      </figcaption>
    </figure>
    <button class="previous"><span @click="prev" class="icon svg-left"></span></button>
    <button class="next"><span @click="next" class="icon svg-right"></span></button>
  </div>
</template>

<style>
.carousel {
  position: relative;
  display: block;
  overflow: hidden;
  position: relative;
  width: 100%;
  z-index: 1;
  overscroll-behavior: touch;
}
.carousel figcaption {
  position: absolute;
  top: 0;
  left: 0;
}
.carousel button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  transition: all 0.4s;
  z-index: 3;
  padding: 0.5rem;
  line-height: 0px;
}
.carousel button.previous {
  left: 15px;
  padding-left: 0.4rem;
}
.carousel button.next {
  right: 15px;
  padding-right: 0.4rem;
}
</style>

<script>
export default {
  name: 'BlockUriTitleImageCarousel',
  props: {
    block: Object,
  },
  computed: {
    currentSlide: function() {
      return this.block.data[Math.abs(this.currentIndex) % this.block.data.length];
    },
  },
  methods: {
    startSlide: function() {
      this.timer = setInterval(this.next, 4000);
    },
    next: function() {
      this.currentIndex += 1;
    },
    prev: function() {
      this.currentIndex -= 1;
    },
  },
  data() {
    return {
      timer: null,
      currentIndex: 0,
    };
  },
};
</script>
