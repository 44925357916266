<template>
  <div class="page-wrap" :class="{ loading: loading, 'no-header': pastHeader, debug: devMode }">
    <!-- Header Location-->
    <template v-if="location.data.header">
      <keep-alive>
        <component :is="location.data.header.component" :location="location.data.header" v-on="$listeners"></component>
      </keep-alive>

      <!-- Header Blocks -->
      <template v-for="childBlock in location.data.header.blocks">
        <keep-alive :key="'header-' + childBlock.id">
          <component :is="childBlock.component" :block="childBlock" v-on="$listeners"></component>
        </keep-alive>
      </template>
    </template>

    <!-- Nav Location-->
    <template v-if="location.data.navBar">
      <keep-alive>
        <component :is="location.data.navBar.component" :location="location.data.navBar" v-on="$listeners"></component>
      </keep-alive>

      <!-- Nav Blocks -->
      <template v-for="childBlock in location.data.navBar.blocks">
        <keep-alive :key="'nav-' + childBlock.id">
          <component :is="childBlock.component" :block="childBlock" v-on="$listeners"></component>
        </keep-alive>
      </template>
    </template>

    <!-- Top Location-->
    <section v-if="location.data.top">
      <component :is="location.data.top.component" :location="location.data.top" v-on="$listeners"></component>

      <!-- Top Blocks -->
      <template v-for="childBlock in location.data.top.blocks">
        <component :is="childBlock.component" :block="childBlock" :key="'top-' + childBlock.id" v-on="$listeners"></component>
      </template>
    </section>

    <!-- Content Location-->
    <template v-if="location.data.content">
      <component :is="location.data.content.component" :location="location.data.content" v-on="$listeners"></component>

      <!-- Content Blocks -->
      <template v-for="childBlock in location.data.content.blocks">
        <component :is="childBlock.component" :block="childBlock" :key="'content-' + childBlock.id" v-on="$listeners"></component>
      </template>
    </template>

    <!-- Bottom Location-->
    <section v-if="location.data.bottom">
      <component :is="location.data.bottom.component" :location="location.data.bottom" v-on="$listeners"></component>

      <!-- Bottom Blocks -->
      <template v-for="childBlock in location.data.bottom.blocks">
        <component :is="childBlock.component" :block="childBlock" :key="'bottom-' + childBlock.id" v-on="$listeners"></component>
      </template>
    </section>

    <!-- Footer Location-->
    <footer v-if="location.data.footer">
      <keep-alive>
        <component :is="location.data.footer.component" :location="location.data.footer" v-on="$listeners"></component>
      </keep-alive>

      <!-- Footer Blocks -->
      <template v-for="childBlock in location.data.footer.blocks">
        <keep-alive :key="'footer-' + childBlock.id">
          <component :is="childBlock.component" :block="childBlock" v-on="$listeners"></component>
        </keep-alive>
      </template>
    </footer>
  </div>
</template>

<style>
@import '../assets/wire-frame.css';
@import '../assets/style.css';
@import '../assets/icons.css';
</style>

<script>
export default {
  name: 'defautPage',
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    location: Object,
  },
  methods: {
    scrollTriggerHeader(inView) {
      this.pastHeader = !inView;
    },
  },
  data() {
    return {
      pastHeader: false,
      devMode: process.env.NODE_ENV == 'production' ? false : true,
    };
  },
};
</script>
