<template>
  <div class="search">
    <!-- Search Icon / Popover -->
    <div v-if="block.properties.type == 'icon'" id="search-icon">
      <span class="icon svg-search" role="img" aria-label="search" @click="togglePopover()"></span>
      <div v-if="popover" id="search-box">
        <div id="autocomplete">
          <div class="has-icon-right">
            <input v-model="query" type="text" class="form-input" placeholder="search" />
            <span class="icon form-icon svg-search" role="img" aria-label="search"></span>
          </div>
        </div>
        <div v-if="popover && results" class="search-results">
          <ul>
            <li id="uri-list" class="results">
              <div class="header">Articles ({{ results.uriCount }})</div>
              <ul>
                <li v-for="uri in results.uri" :key="uri">
                  <details class="accordion">
                    <summary class="accordion-header">
                      {{ uri }}
                    </summary>
                    <div class="accordion-body">
                      Summary ???
                    </div>
                  </details>
                </li>
              </ul>
            </li>
            <li id="tag-list" class="results">
              <div class="header">Tags ({{ results.tagCount }})</div>
              <ul>
                <li v-for="(result, index) in results.tag" :key="'tag-' + index">
                  <details class="accordion">
                    <summary class="accordion-header">
                      <router-link to="/" class="result-item">{{ result.tag }}</router-link>
                      <i class="icon svg-right"></i>
                    </summary>
                    <div class="accordion-body">
                      <div v-for="uri in result.uri" :key="'tag-' + uri">
                        {{ uri }}
                      </div>
                    </div>
                  </details>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <!-- Search bar -->
    <div v-if="block.properties.type == 'bar'" id="search-bar">
      <div class="has-icon-left">
        <input type="text" class="form-input" v-model="query" placeholder="Search" />
        <span class="svg-search form-icon" role="img" aria-label="search"></span>
      </div>
    </div>
  </div>
</template>

<style>
#search-icon {
  position: relative;
}
#search-box {
  position: absolute;
}
#search-icon .search-results {
  overflow-y: auto;
}
#search-icon .search-results li {
  list-style: none;
}
#search-icon .accordion-header {
  display: flex;
  align-items: center;
}
#search-icon .result-item {
  flex-grow: 1;
}
</style>

<script>
import api from '@/api/api.service.js';
export default {
  name: 'blockSearch',
  props: {
    block: Object,
  },
  watch: {
    query: function(val, oldVal) {
      if (val.length > 3 && val !== oldVal) {
        // TODO - Test this a bit. I think its still calling the server a lot
        if (!this.searching) {
          setTimeout(() => {
            api.callBlockMethod('search', 'autoComplete', val).then((results) => {
              this.results = results;
            });
            this.searching = false;
          }, 1000); // 1 sec delay
        }
        this.searching = true;
      }
    },
  },
  computed: {},
  methods: {
    togglePopover() {
      this.popover = this.popover ? false : true;
    },
  },
  data() {
    return {
      popover: false,
      query: null,
      searching: false,
      results: {
        tag: {},
        tagCount: 5,
        uri: [],
        uriCount: 3,
      },
    };
  },
};
</script>
