<template><span></span></template>
<script>
export default {
  name: 'BlockInfluencersoft',
  props: {
    block: Object,
  },
  computed: {},
  mounted() {
    this.block.data.forEach((form) => {
      let script = document.createElement('script');
      let id = form.replace(this.block.properties.cidPrefix, '');
      script.src = 'https://api.siter.influencersoft.com/web_forms/' + id + '/form_loader.js?cid=' + form;
      document.body.appendChild(script);
    });
  },
  data() {
    return {};
  },
};
</script>
