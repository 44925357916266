<template>
  <nav :class="block.properties.class">
    <ul v-if="parsedShowNav" class="not-mobile">
      <navItem
        v-for="(navItem, index) in block.data"
        v-on="$listeners"
        :type="navItem.itemType"
        :navigateTo="navItem.navigateTo"
        :title="navItem.title"
        :offset="block.properties.offset"
        :items="navItem.items"
        :key="'nav-item-' + index"
      />
    </ul>

    <!-- Mobile -->
    <span v-if="parsedShowMobileNavIcon" class="btn mobile-nav">
      <span class="icon svg-hamburger" role="img" aria-label="mobile-nav" @click="toggleNav"></span>
    </span>
    <template v-if="parsedShowMobileNav">
      <div class="mobile">
        <ul>
          <navItemMobile
            v-for="(navItem, index) in block.data"
            v-on="$listeners"
            :type="navItem.itemType"
            :navigateTo="navItem.navigateTo"
            :title="navItem.title"
            :offset="block.properties.offset"
            :items="navItem.items"
            :accordion="true"
            :key="'nav-item-' + index"
          />
        </ul>

        <!-- Click Anywhere to Close -->
        <span @click="toggleNav"></span>
      </div>
    </template>
  </nav>
</template>

<style></style>

<script>
import navItem from '@/components/navItem';
import navItemMobile from '@/components/navItemMobile';

export default {
  name: 'blockNavigation',
  components: {
    navItem,
    navItemMobile,
  },
  props: {
    block: Object,
  },
  computed: {
    // TODO Remove all these once I fix to return proper data type for block properties
    parsedShowNav() {
      return parseInt(this.block.properties.showNav) > 0 ? true : false;
    },
    parsedShowMobileNav() {
      return parseInt(this.block.properties.showMobileNav) > 0 ? true : false;
    },
    parsedShowMobileNavIcon() {
      return parseInt(this.block.properties.showMobileNavIcon) > 0 ? true : false;
    },
  },
  methods: {
    toggleNav() {
      this.navOpen = this.navOpen ? false : true;
    },
  },
  data() {
    return {
      navOpen: false,
    };
  },
};
</script>
