<template>
  <div :class="[block.data.class]" :id="block.properties.id">
    <ul v-for="(group, groupName) in block.data.groupList" :key="groupName" :class="groupName">
      <li v-for="(childBlock, index) in group" :key="groupName + '-' + index" :class="childBlock.type">
        <component :is="childBlock.component" :block="childBlock"></component>
      </li>
    </ul>
  </div>
</template>

<style>

</style>

<script>
export default {
  name: 'groupAppBox',
  props: {
    block: Object,
  },
  computed: {},
  data() {
    return {};
  },
};
</script>
