<template>
  <header class="header text-primary">
    <div class="inner">
      <!-- Left Location-->
      <div class="left">
        <div class="branding bg-primary">
          <div v-if="!location.properties.logo" class="title h1">
            <router-link to="/" class="text-primary">{{ location.properties.siteName }}</router-link>
          </div>
          <div v-if="location.properties.logo" class="logo">
            <router-link to="/">
              <img class="img-responsive" :src="location.properties.logo.src" :alt="location.properties.logo.alt" :width="location.properties.logo.width" :height="location.properties.logo.height" />
            </router-link>
          </div>
        </div>

        <template v-if="location.data.left">
          <component :is="location.data.left.component" :location="location.data.left" v-on="$listeners"></component>

          <template v-for="childBlock in location.data.left.blocks">
            <component :is="childBlock.component" :block="childBlock" :key="'header-left-' + childBlock.id" v-on="$listeners"></component>
          </template>
        </template>
      </div>

      <!-- Center Location-->
      <div class="center">
        <template v-if="location.data.center">
          <component :is="location.data.center.component" :location="location.data.center" v-on="$listeners"></component>

          <template v-for="childBlock in location.data.center.blocks">
            <component :is="childBlock.component" :block="childBlock" :key="'header-center-' + childBlock.id" v-on="$listeners"></component>
          </template>
        </template>
      </div>

      <!-- Right Location-->
      <div class="right">
        <template v-if="location.data.right">
          <component :is="location.data.right.component" :location="location.data.right" v-on="$listeners"></component>

          <template v-for="childBlock in location.data.right.blocks">
            <component :is="childBlock.component" :block="childBlock" :key="'header-right-' + childBlock.id" v-on="$listeners"></component>
          </template>
        </template>
      </div>
    </div>

    <!-- Bottom Location -->
    <div class="bottom">
      <template v-if="location.data.bottom">
        <component :is="location.data.bottom.component" :location="location.data.bottom" v-on="$listeners"></component>

        <template v-for="childBlock in location.data.bottom.blocks">
          <component :is="childBlock.component" :block="childBlock" :key="'header-center-' + childBlock.id" v-on="$listeners"></component>
        </template>
      </template>
    </div>
  </header>
</template>

<style>
.header {
  position: fixed;
  top: 0;
  z-index: 4;
  width: 100%;
  background-color: white;
}
.header .inner {
  display: flex;
}
.header .left {
  position: relative;
}
.header .left .branding {
  position: absolute;
  width: 300px;
  bottom: -4rem;
  left: 25px;
  z-index: 3;
  padding: 0.5rem;
  transition: 1s;
}
.past-header .header .left .branding {
  transition: 1s;
  bottom: 75px;
}
.header .branding .title {
  white-space: nowrap;
}
.header .center {
  flex: 1;
}
.header .right .nav-wrapper {
  margin-top: 0.25rem;
  margin-right: 2rem;
}
.header .right .nav.not-mobile {
  flex-direction: row;
  text-transform: uppercase;
}
.header .nav-mobile .nav {
  margin-top: 2rem;
}
.header .right .nav-item {
  margin: 0;
  margin-left: 0.5rem;
}
.header .right .nav-item > a {
  padding: 0;
}
.header .right .nav-item > a:hover,
.header .right .nav-item > a:active {
  color: #00b2e2;
}
.header .right .mobile-nav-toggle {
  display: none;
  border: none;
  margin: 0.5rem 0;
}
.header .right .nav-mobile .nav-item {
  margin-top: 0px;
  font-weight: 600;
  text-transform: uppercase;
}
.header .right .nav-mobile .btn.btn-link {
  text-align: left;
}
@media (max-width: 840px) {
  .header .right .nav.not-mobile {
    display: none;
  }
  .header .right .mobile-nav-toggle {
    display: block;
  }
}
@media (max-width: 480px) {
.header .left .branding {
  width: 200px;
  bottom: -2rem;
}
}
</style>

<script>
export default {
  name: 'heritageHeader',
  props: {
    location: Object,
  },
  head: {
    title: function() {
      return {
        inner: this.location.properties.pageTitle,
        separator: '|',
        complement: this.location.properties.siteName,
      };
    },
    meta: function() {
      return [
        // Basic
        { rel: 'canonical', href: this.location.properties.path, id: 'canonical' },
        { name: 'description', content: this.location.properties.synopsis, id: 'synopsis' },
        // Twitter Share
        { name: 'twitter:title', content: this.location.properties.pageTitle, id: 'twitterTitle' },
        { name: 'twitter:url', content: this.location.properties.path, id: 'twitterUrl' },
        { name: 'twitter:description', content: this.location.properties.synopsis, id: 'twitterDesc' },
        // Open Graph
        { name: 'og:site_name', content: this.location.properties.siteName, id: 'ogSite' },
        { name: 'og:type', content: 'website', id: 'ogType' },
        { name: 'og:title', content: this.location.properties.pageTitle, id: 'ogTitle' },
        { name: 'og:description', content: this.location.properties.synopsis, id: 'ogDesc' },
        { name: 'og:url', content: this.location.properties.path, id: 'ogUrl' },
      ];
    },
  },
  data() {
    return {};
  },
};
</script>
