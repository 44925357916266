<template>
  <div :class="uriClass" id="theme">
    <component v-if="root" :is="root.component" :location="root" :loading="loading" @logAction="logAction" @toast="makeToast"></component>
    <div v-if="toast.message" id="toast" :class="toast.class" class="toast">
      <button @click="eatToast" class="btn btn-clear float-right"></button>
      {{ toast.message }}
    </div>
  </div>
</template>

<style>
#theme {
  position: relative;
}
#toast {
  position: fixed;
}
</style>

<script>
import api from '@/api/api.service.js';
import { blockLoader } from '@/loader/loader';

export default {
  name: 'Theme',
  mixins: [blockLoader],
  props: {
    uri: String,
  },
  mounted() {
    this.loading = true;
    this.loadUri(this.uri);
    this.$el.addEventListener('click', this.logClick);
  },
  computed: {
    root() {
      return this.data ? this.data.block : null;
    },
    uriClass() {
      return this.uri || 'home';
    },
  },
  methods: {
    loadUri(uriCode) {
      this.loading = true;
      api.loadURI(uriCode).then((data) => {
        if (data && data.block) {
          this.loadBlock(data.block, data.themeCode || 'default');
        } else {
          // TODO - Log this error
          this.makeToast({ message: 'Missing Root Block', butter: 'error' });
        }
        this.data = { ...data };
        this.$emit('updateHead');
        this.loading = false;
      });
    },
    logAction(payload) {
      // Error Log
      if (payload.action.toLowerCase() == 'error') {
        // TODO Make Error Log
      }
    },
    logClick(event) {
      if (!event.target.classList.contains('log-click')) return;
      api.logClick(event.target.getAttribute('data-type'), event.target.getAttribute('data-detail'), event.target.getAttribute('data-uri'));
    },
    makeToast(toast) {
      let c = [];
      if (toast.butter) {
        if (toast.butter == 'success') {
          c.push('toast-success');
        }
        if (toast.butter == 'warning') {
          c.push('toast-warning');
        }
        if (toast.butter == 'error') {
          c.push('toast-error');
        }
      }
      if (toast.class) {
        c.push(toast.class); // TODO make this accept an array
      }
      this.$set(this.toast, 'message', toast.message);
      this.$set(this.toast, 'class', c.join(' '));
    },
    eatToast() {
      this.toast = {};
    },
  },
  data() {
    return {
      loading: false,
      data: {},
      toast: {},
    };
  },
  beforeRouteUpdate(to, from, next) {
    this.data = {};
    this.loadUri(to.params.uri);
    next();
  },
  beforeRouteLeave(to, from, next) {
    this.data = {};
    this.loadUri(to.params.uri || null);
    next();
  },
  head: {
    title: function() {
      return this.data && this.data.site
        ? {
            inner: this.data.windowTitle || '',
            separator: '|',
            complement: this.data.windowTitle || this.data.site.name,
          }
        : { inner: 'loading' }; // TODO Make this default dynamic somehow
    },
    meta: function() {
      return this.data.site
        ? [
            // Basic
            { name: 'description', content: this.data.synopsis, id: 'synopsis' },
            // Google
            { itemprop: 'name', content: this.data.title, id: 'googleTitle' },
            { itemprop: 'description', content: this.data.synopsis, id: 'googleDesc' },

            // Twitter Share
            { name: 'twitter:title', content: this.data.title, id: 'twitterTitle' },
            { name: 'twitter:url', content: this.data.url, id: 'twitterUrl' },
            { name: 'twitter:description', content: this.data.synopsis, id: 'twitterDesc' },
            // { name: 'twitter:image', content: 'TODO - Logo Path 4 Twitter', id: 'twitterImage'},
            // { name: 'twitter:site', content: 'TODO - @A Twitter URL', id: 'twitterPage'},
            // { name: 'twitter:creator', content: 'TODO - @A Twitter UserName', id: 'twittername'},
            // Open Graph
            { property: 'og:site_name', content: this.data.site ? this.data.site.name : '', id: 'ogSite' },
            { property: 'og:type', content: 'website', id: 'ogType' },
            { property: 'og:title', content: this.data.title, id: 'ogTitle' },
            { property: 'og:description', content: this.data.synopsis, id: 'ogDesc' },
            // { property: 'og:image', content: 'TODO - Logo Path 4 Facebook', id: 'ogImage'},
            { property: 'og:url', content: this.data.url, id: 'ogUrl' },
          ]
        : [];
    },
    link: function() {
      return [{ rel: 'canonical', href: this.data.url, id: 'canonical' }];
    },
  },
};
</script>
