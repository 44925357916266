<template>
  <div class="intro-text">
    <h2 v-if="block.properties.heading" class="heading" >{{ block.properties.heading }}</h2>
    <p v-if="block.properties.subheading" class="subheading" >{{ block.properties.subheading }}</p>
    <p v-if="block.properties.text" class="text" >{{ block.properties.text }}</p>
  </div>
</template>

<style></style>

<script>
export default {
  name: 'BlockIntroText',
  props: {
    block: Object,
  },
  computed: {},
  data() {
    return {};
  },
};
</script>
