<template>
  <nav v-if="block.data" class="table-of-contents">
    <div @click="isOpen = isOpen ? false : true" class="title">
      <div>
        <h1 v-if="block.properties.pageTitle">{{ block.properties.pageTitle }}</h1>
        <h2 v-if="block.properties.heading">{{ block.properties.heading }}</h2>
      </div>
      <button type="button" class="btn dropdown-toggle">
        <span class="icon svg-down" role="button" :aria-label="'title' + ' Dropdown'"></span>
      </button>
    </div>

    <transition name="slide">
      <ul class="nav" v-if="isOpen">
        <li v-for="section in block.data" :key="section.id" class="nav-item heading">
          <a href="#" v-scroll-to="{ el: section.id, offset: parseInt(block.properties.offset) }">{{ section.heading }}</a>
          <ul class="nav">
            <li v-for="subsection in section.sections" :key="subsection.id" class="nav-item subheading">
              <a href="#" v-scroll-to="{ el: subsection.id, offset: parseInt(block.properties.offset) }">{{ subsection.heading }}</a>
            </li>
          </ul>
        </li>
      </ul>
    </transition>
  </nav>
</template>

<script>
export default {
  name: 'BlockTableOfContents',
  props: {
    block: Object,
  },
  computed: {},
  data() {
    return {
      isOpen: false,
    };
  },
};
</script>
