<template>
  <article :class="[block.data.class]" class="home text-primary" :id="block.properties.id">
    <div v-for="(group, groupName) in block.data.groupList" :key="groupName" :class="groupName">
      <template v-for="(childBlock, index) in group">
        <component :is="childBlock.component" :block="childBlock" :key="groupName + '-' + index" v-on="$listeners"></component>
      </template>
    </div>
  </article>
</template>

<style>
.home {
  position: relative;
  height: 100vh;
  max-height: 100vh;
  overflow: hidden;
  z-index: 1;
}

.home .underlay {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.home .underlay img {
  object-fit: cover;
  height: 100%;
  width: 100%;
}
.home .underlay::after {
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}
.home .overlay {
  position: absolute;
  right: 0;
  bottom: 20%;
  margin-right: 3rem;
  z-index: 2;
}
.home .overlay .link {
  display: block;
  background-color: #48ff00;
  padding: 1.5rem;
  text-transform: uppercase;
  font-weight: 700;
}
.home .overlay .link > a {
  color: black;
  font-size: 2vw;
}
@media (max-width: 600px) {
  .home .overlay .link > a {
    font-size: 4vw;
  }
}
</style>

<script>
export default {
  name: 'groupHome',
  props: {
    block: Object,
  },
  data() {
    return {};
  },
};
</script>
