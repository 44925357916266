<template>
  <div class="existing-article" v-html="block.data"></div>
</template>

<style></style>

<script>
export default {
  name: 'BlockExistingArticle',
  props: {
    block: Object,
  },
  methods: {
    logClick(event) {
      if (!event.target.classList.contains('btn-track')) return;
      let payload = {
        action: 'click',
        type: event.target.getAttribute('data-type'),
        typeDetail: event.target.getAttribute('data-detail'),
        uri: event.target.getAttribute('data-uri'),
      };
      this.$emit('logAction', payload);
    },
  },
  mounted() {
    this.$el.addEventListener('click', this.logClick);
  },
  data() {
    return {};
  },
  head: {
    script: function() {
      return this.block.properties.javascript;
    },
    link: function() {
      return [...this.block.properties.preconnect, ...this.block.properties.stylesheet];
    },
  },
};
</script>
