<template>
  <div v-html="block.data" :class="[block.properties.class]" class="google-document"></div>
</template>

<style></style>

<script>
export default {
  name: 'BlockGoogleDocument',
  props: {
    block: Object,
  },
  computed: {},
  data() {
    return {};
  },
};
</script>
