<template>
  <li :class="{ dropdown: hasChildren }">
    <router-link v-if="type == 'route'" :to="navigateTo">{{ title }}</router-link>
    <a v-if="type == 'external'" :href="navigateTo">{{ title }}</a>
    <a v-if="type == 'anchor'" href="#" v-scroll-to="{ el: navigateTo, offset: parsedOffset }">{{ title }}</a>
    <a v-if="type == 'emit'" @click="emit(navigateTo)">{{ title }}</a>

    <button v-if="hasChildren" type="button" class="btn dropdown-toggle">
      <span class="icon svg-down" role="button" :aria-label="title + ' Dropdown'"></span>
    </button>

    <!-- Dropdown Tear -->
    <ul v-if="hasChildren">
      <navItem
        v-for="(item, index) in items"
        v-on="$listeners"
        :type="item.itemType"
        :navigateTo="item.navigateTo"
        :title="item.title"
        :offset="offset"
        :items="item.items"
        :key="'nav-item-' + index"
      />
    </ul>
  </li>
</template>

<style>
.dropdown > ul {
  display: none;
}
</style>

<script>
import navItem from '@/components/navItem';

export default {
  name: 'navItem',
  components: {
    navItem,
  },
  props: {
    type: String,
    navigateTo: String,
    title: String,
    offset: [String, Number],
    items: [Array],
    accordion: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    parsedOffset() {
      return parseInt(this.offset);
    },
    hasChildren() {
      return this.items && this.items.length > 0;
    },
  },
  methods: {
    emit(emitPath) {
      emitPath = emitPath.split('/');
      if (emitPath.length === 2) {
        let eventName = emitPath[0];
        let eventValue = emitPath[1];
        this.$root.$emit(eventName, eventValue);
      } else {
        this.$emit('toast', { butter: 'error', message: 'Emit Path Not Improperly Structured!' });
      }
    },
  },
  data() {
    return {
      authCode: '',
    };
  },
};
</script>
