<template>
  <ul class="sitemap">
    <li v-for="uri in block.data" :key="uri.uriCode" class="uri">
      <router-link :to="uri.uriCode">{{ uri.title }}</router-link>
    </li>
  </ul>
</template>

<style>
.sitemap {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-gap: 10px;
}
.sitemap .uri {
  display: flex;
  list-style-type: none;
  text-align: center;
  align-items: center;
  justify-content: center;
}
</style>

<script>
export default {
  name: 'BlockSitemap',
  props: {
    block: Object,
  },
  data() {
    return {};
  },
};
</script>
