<template>
  <article :class="[block.properties.class]" class="columns" :id="block.properties.id">
    <div v-for="(group, groupName) in block.data.groupList" :key="groupName" :class="groupName" class="column col-6">
      <template v-for="(childBlock, index) in group">
        <component :is="childBlock.component" :block="childBlock" :key="groupName + '-' + index"></component>
      </template>
    </div>
  </article>
</template>

<style></style>

<script>
export default {
  name: 'groupTwoColumns',
  props: {
    block: Object,
  },
  mounted() {},
  data() {
    return {};
  },
};
</script>
